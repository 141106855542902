import React, { useState, useMemo, useEffect, useRef } from 'react';
import { CssBaseline, Container, Toolbar, ThemeProvider, createTheme, Snackbar } from '@mui/material';
import Header from './components/Header';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import CvCertifications from './components/CvCertifications';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
  const [mode, setMode] = useState('dark');
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [projects, setProjects] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const projectsRef = useRef(null);

  useEffect(() => {
    fetch('/mount/projects.json')
      .then(response => response.json())
      .then(data => {
        setProjects(data.projects);
      })
      .catch(error => {
        console.error('Error loading projects:', error);
      });
  }, []);

  const handleSkillSelect = (skill) => {
    if (skill === selectedSkill) {
      setSelectedSkill(null);
    } else {
      const hasMatchingProjects = projects.some(project =>
        project.technologies.some(tech => skill.technologies.includes(tech))
      );
      if (hasMatchingProjects) {
        setSelectedSkill(skill);
        // Scroll to projects section with dynamic offset
        if (projectsRef.current) {
          const header = document.querySelector('header');
          const headerOffset = header ? header.offsetHeight : 0;
          const elementPosition = projectsRef.current.getBoundingClientRect().top;
          const additionalOffset = 20; // Adjust this value to scroll higher or lower
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset - additionalOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      } else {
        setErrorMessage(`No projects match the skill: ${skill.title}`);
      }
    }
  };


  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', mode);
  }, [mode]);
  
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
                // Light mode palette
                primary: {
                  main: '#1976d2',
                  light: '#42a5f5',
                  dark: '#1565c0',
                },
                secondary: {
                  main: '#9c27b0',
                  light: '#ba68c8',
                  dark: '#7b1fa2',
                },
                background: {
                  default: '#f5f5f5',
                  paper: '#ffffff',
                },
                text: {
                  primary: '#212121',
                  secondary: '#757575',
                },
              }
            : {
                // Dark mode palette
                primary: {
                  main: '#90caf9',
                  light: '#e3f2fd',
                  dark: '#42a5f5',
                },
                secondary: {
                  main: '#ce93d8',
                  light: '#f3e5f5',
                  dark: '#ab47bc',
                },
                background: {
                  default: '#1a2027',
                  paper: '#252c35',
                },
                text: {
                  primary: '#e0e0e0',
                  secondary: '#b0bec5',
                },
              }),
          error: {
            main: '#ef5350',
          },
          warning: {
            main: '#ff9800',
          },
          info: {
            main: '#29b6f6',
          },
          success: {
            main: '#66bb6a',
          },
          divider: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          h1: { fontWeight: 300 },
          h2: { fontWeight: 400 },
          h3: { fontWeight: 500 },
          h4: { fontWeight: 500 },
          h5: { fontWeight: 500 },
          h6: { fontWeight: 500 },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 8,
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                borderRadius: 8,
              },
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <Header />
          <Toolbar />
          <Container>
            <About />
            <Skills onSkillSelect={handleSkillSelect} selectedSkill={selectedSkill} />
            <div ref={projectsRef}>
              <Projects selectedSkill={selectedSkill} projects={projects} />
            </div>
            <CvCertifications />
            <Contact />
          </Container>
          <Footer />
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={Boolean(errorMessage)}
          autoHideDuration={3000}
          onClose={() => setErrorMessage('')}
          message={errorMessage}
        />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;