import React, { useState, useEffect } from 'react';
import { Typography, Paper, Box, TextField, Button, Grid, IconButton, Snackbar, useTheme, CircularProgress } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import SendIcon from '@mui/icons-material/Send';

const Contact = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [backendUrl, setBackendUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('/mount/config.json')
      .then(response => response.json())
      .then(data => {
        setBackendUrl(data.backendUrl);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error loading config:', error);
        setSnackbarMessage('Error loading configuration. Please try again later.');
        setSnackbarOpen(true);
        setIsLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!backendUrl) {
      setSnackbarMessage('Backend URL not configured. Please try again later.');
      setSnackbarOpen(true);
      return;
    }
    try {
      const response = await fetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data);
      setSnackbarMessage('Message sent successfully!');
      setSnackbarOpen(true);
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error sending message:', error);
      setSnackbarMessage('Failed to send message. Please try again.');
      setSnackbarOpen(true);
    }
  };

  const socialLinks = [
    { icon: <EmailIcon />, url: 'mailto:pkhovic@gmail.com', label: 'Email' },
    { icon: <LinkedInIcon />, url: 'https://www.linkedin.com/in/khovic', label: 'LinkedIn' },
    { icon: <GitHubIcon />, url: 'https://github.com/khovic', label: 'GitHub' },
  ];

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper 
      elevation={3} 
      id="contact" 
      className="glass fade-in" 
      sx={{ 
        p: 4, 
        my: 4, 
        backgroundColor: theme.palette.background.paper,
        borderRadius: '16px',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
        Get in Touch
      </Typography>
      <Typography variant="body1" paragraph sx={{ mb: 4 }}>
        I'm always open to new opportunities and collaborations. Feel free to reach out!
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              variant="outlined"
              sx={{ 
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.light,
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
              sx={{ 
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.light,
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="message"
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              multiline
              rows={4}
              variant="outlined"
              sx={{ 
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.light,
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          endIcon={<SendIcon />}
          sx={{ 
            mt: 4, 
            py: 1.5,
            px: 4,
            borderRadius: '25px',
            fontWeight: 'bold',
            transition: 'transform 0.2s',
            '&:hover': { 
              transform: 'translateY(-3px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            },
          }}
        >
          Send Message
        </Button>
      </Box>
      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          Connect with me
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          {socialLinks.map((link, index) => (
            <IconButton
              key={index}
              component="a"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={link.label}
              sx={{ 
                mx: 1.5, 
                color: theme.palette.text.secondary,
                transition: 'all 0.3s',
                '&:hover': { 
                  color: theme.palette.primary.main,
                  transform: 'translateY(-3px)',
                },
              }}
            >
              {link.icon}
            </IconButton>
          ))}
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Paper>
  );
};

export default Contact;