import React from 'react';
import { Typography, Box, Container, IconButton, useTheme } from '@mui/material';
import { GitHub, LinkedIn, Twitter } from '@mui/icons-material';

const Footer = () => {
  const theme = useTheme();

  // const socialLinks = [
  //   { icon: <GitHub />, url: 'https://github.com/Khovic', label: 'GitHub' },
  //   { icon: <LinkedIn />, url: 'https://www.linkedin.com/in/khovic/', label: 'LinkedIn' },
  //   { icon: <Twitter />, url: 'https://twitter.com/yourusername', label: 'Twitter' },
  // ];

  return (
    <Box 
      component="footer" 
      sx={{ 
        py: 4, 
        bgcolor: theme.palette.background.paper, 
        color: theme.palette.text.secondary,
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* <Box sx={{ mb: 2 }}> */}
            {/* {socialLinks.map((link, index) => (
              <IconButton
                key={index}
                component="a"
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={link.label}
                sx={{ 
                  mx: 1,
                  color: theme.palette.text.secondary,
                  '&:hover': { 
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {link.icon}
              </IconButton>
            ))}
          </Box> */}
          <Typography variant="body2" align="center">
            &copy; {new Date().getFullYear()} Dan Polyukhovich. All rights reserved.
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 1 }}>
            Built with React and Material-UI
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;