import React, { useRef, useState, useEffect } from 'react';
import { Typography, Paper, Card, CardContent, CardMedia, Button, Box, useTheme, IconButton, Chip } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Projects = ({ selectedSkill, projects }) => {
  const theme = useTheme();
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const filteredProjects = selectedSkill
    ? projects.filter(project =>
        project.technologies.some(tech =>
          selectedSkill.technologies.includes(tech)
        )
      )
    : projects;

  const checkScrollability = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  useEffect(() => {
    checkScrollability();
    window.addEventListener('resize', checkScrollability);
    return () => window.removeEventListener('resize', checkScrollability);
  }, [filteredProjects]);

  const scroll = (scrollOffset) => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollBy({ left: scrollOffset, behavior: 'smooth' });
      setTimeout(checkScrollability, 300); // Check after scroll animation
    }
  };

  return (
    <Paper 
      elevation={3} 
      id="projects" 
      className="glass fade-in" 
      sx={{ 
        p: 4, 
        my: 4, 
        backgroundColor: theme.palette.background.paper,
        borderRadius: '16px',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
        Projects {selectedSkill && `(Filtered by ${selectedSkill.title})`}
      </Typography>
      <Box sx={{ position: 'relative', height: 500 }}>
        <Box
          ref={scrollContainerRef}
          sx={{
            display: 'flex',
            overflowX: 'auto',
            overflowY: 'hidden',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            gap: 3,
            pb: 2,
            height: '100%',
            alignItems: 'center',
          }}
          onScroll={checkScrollability}
        >
          {filteredProjects.map((project, index) => (
            <Card 
              key={index}
              sx={{ 
                width: 300,
                height: 450,
                flex: '0 0 auto',
                display: 'flex', 
                flexDirection: 'column',
                backgroundColor: theme.palette.background.default,
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: `0 10px 20px var(--shadow-color-${theme.palette.mode})`,
                },
              }}
            >
              <CardMedia
                component="img"
                image={project.image}
                alt={project.title}
                sx={{ 
                  height: 180,
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
              />
              <CardContent sx={{ 
                flexGrow: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'space-between',
                height: 'calc(100% - 180px)',
                overflow: 'auto'
              }}>
                <div>
                  <Typography variant="h6" component="div" gutterBottom sx={{ fontWeight: 'bold' }}>
                    {project.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {project.description}
                  </Typography>
                  <Box sx={{ mb: 2 }}>
                    {project.technologies.map((tech, i) => (
                      <Chip
                        key={i}
                        label={tech}
                        size="small"
                        sx={{
                          mr: 1,
                          mb: 1,
                          backgroundColor: selectedSkill && selectedSkill.technologies.includes(tech)
                            ? theme.palette.primary.main
                            : theme.palette.background.paper,
                          color: selectedSkill && selectedSkill.technologies.includes(tech)
                            ? theme.palette.primary.contrastText
                            : theme.palette.text.primary,
                          fontWeight: selectedSkill && selectedSkill.technologies.includes(tech)
                            ? 'bold'
                            : 'normal',
                          border: `1px solid ${theme.palette.divider}`,
                        }}
                      />
                    ))}
                  </Box>
                </div>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: project.liveLink ? 'space-between' : 'flex-start',
                  mt: 2 
                }}>
                  <Button 
                    variant="outlined" 
                    startIcon={<GitHubIcon />}
                    href={project.githubLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  >
                    GitHub
                  </Button>
                  {project.liveLink && (
                    <Button 
                      variant="contained" 
                      endIcon={<LaunchIcon />}
                      href={project.liveLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="small"
                    >
                      Live Demo
                    </Button>
                  )}
                </Box>
              </CardContent>
              </Card>
          ))}
        </Box>
        {showLeftArrow && (
          <IconButton 
            onClick={() => scroll(-300)} 
            sx={{ 
              position: 'absolute', 
              left: -20, 
              top: '50%', 
              transform: 'translateY(-50%)',
              backgroundColor: theme.palette.background.paper,
              '&:hover': { backgroundColor: theme.palette.action.hover },
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
        {showRightArrow && (
          <IconButton 
            onClick={() => scroll(300)} 
            sx={{ 
              position: 'absolute', 
              right: -20, 
              top: '50%', 
              transform: 'translateY(-50%)',
              backgroundColor: theme.palette.background.paper,
              '&:hover': { backgroundColor: theme.palette.action.hover },
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        )}
      </Box>
    </Paper>
  );
};

export default Projects;