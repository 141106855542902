import React from 'react';
import { Typography, Paper, Button, Grid, Card, CardContent, useTheme, Box } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import awsSolutionsArchitectIcon from '../assets/aws-solutions-architect.png';
import awsDeveloperIcon from '../assets/aws-developer.png';

const certifications = [
  { 
    title: 'AWS Certified Solutions Architect – Associate', 
    icon: awsSolutionsArchitectIcon,
    url: 'https://www.credly.com/badges/d456f587-fc18-49a5-b338-eaf547a9590b'
  },
  { 
    title: 'AWS Certified Developer – Associate', 
    icon: awsDeveloperIcon,
    url: 'https://www.credly.com/badges/b2439f95-2bc9-48ff-b614-a908ffcc5e9f'
  },
];

const CvCertifications = () => {
  const theme = useTheme();

  return (
    <Paper 
      elevation={3} 
      id="cvcertifications" 
      className="glass fade-in" 
      sx={{ 
        p: 4, 
        my: 4, 
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: '16px',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
        Curriculum Vitae & Certifications
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" paragraph>
            I specialize in deploying and maintaining both Development and Production environments. Collaborating with development teams in design of resilient microservice-based applications, create CI/CD pipelines, and implement comprehensive monitoring solutions. Additionally, I have experience in developing applications and automations. For more details, please view my CV.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppIcon />}
            href="/mount/cv.pdf"
            target="_blank"
            rel="noopener"
            sx={{ 
              mt: 2, 
              py: 1.5,
              px: 3,
              borderRadius: '25px',
              fontWeight: 'bold',
              transition: 'all 0.3s ease',
              '&:hover': { 
                backgroundColor: theme.palette.primary.dark,
                transform: 'translateY(-3px)',
                boxShadow: `0 4px 8px ${theme.palette.primary.main}40`,
              } 
            }}
          >
            Download CV
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          {certifications.map((cert, index) => (
            <Card 
              key={index}
              component="a"
              href={cert.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 2, 
                backgroundColor: theme.palette.background.default,
                borderRadius: '8px',
                transition: 'all 0.3s ease',
                textDecoration: 'none',
                '&:hover': {
                  transform: 'translateX(5px)',
                  boxShadow: `0 2px 4px ${theme.palette.primary.main}40`,
                }
              }}
            >
              <Box
                component="img"
                src={cert.icon}
                alt={cert.title}
                sx={{
                  width: 80,
                  height: 80,
                  m: 2,
                  filter: theme.palette.mode === 'dark' ? 'invert(0)' : 'none',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  }
                }}
              />
              <CardContent>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    fontWeight: 'bold',
                    color: theme.palette.primary.main
                  }}
                >
                  {cert.title}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CvCertifications;