import React from 'react';
import { Typography, Paper, Grid, Card, CardContent, Box, useTheme } from '@mui/material';
import { Cloud, Code, Storage, Settings, BuildCircle, LegendToggle, Archive, Source, AutoAwesomeMotion } from '@mui/icons-material';

const skills = [
  { title: 'CI/CD', description: 'Jenkins, ArgoCD', icon: <BuildCircle />, technologies: ['Jenkins', 'ArgoCD'] },
  { title: 'Cloud', description: 'AWS, Oracle Cloud', icon: <Cloud />, technologies: ['AWS', 'Oracle Cloud'] },
  { title: 'Container Orchestration', description: 'Docker Compose, Helm, Kubernetes, Openshift', icon: <Storage />, technologies: ['Docker', 'Helm', 'Kubernetes', 'Openshift'] },
  { title: 'Automation', description: 'Bash, Python, Ansible', icon: <Settings />, technologies: ['Bash', 'Python', 'Ansible'] },
  { title: 'Infrastructure as Code', description: 'Terraform, CloudFormation', icon: <Code />, technologies: ['Terraform', 'CloudFormation'] },
  { title: 'Monitoring', description: 'Prometheus, Grafana, EFK Stack, Datadog, OpenTelemetry, Jaeger Tracing', icon: <LegendToggle />, technologies: ['Prometheus', 'Grafana', 'EFK Stack', 'Datadog', 'OpenTelemetry', 'Jaeger Tracing'] },
  { title: 'Source Code Management', description: 'GitLab, GitHub, BitBucket', icon: <Source />, technologies: ['GitLab', 'GitHub', 'BitBucket'] },
  { title: 'Artifact Management', description: 'Artifactory, Harbor', icon: <Archive />, technologies: ['Artifactory', 'Harbor'] },
  { title: 'Other', description: 'Linux, Kafka, MongoDB, Flask', icon: <AutoAwesomeMotion />, technologies: ['Linux', 'Kafka', 'MongoDB', 'Flask'] },
];

const Skills = ({ onSkillSelect, selectedSkill }) => {
  const theme = useTheme();

  const handleSkillClick = (skill) => {
    onSkillSelect(skill);
  };

  return (
    <Paper 
      elevation={3} 
      id="skills" 
      className="glass fade-in" 
      sx={{ 
        p: 4, 
        my: 4, 
        backgroundColor: theme.palette.background.paper,
        borderRadius: '16px',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
        Skills
      </Typography>
      <Grid container spacing={3}>
        {skills.map((skill, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              sx={{ 
                height: '100%',
                backgroundColor: selectedSkill === skill ? theme.palette.primary.main : theme.palette.background.default,
                color: selectedSkill === skill ? theme.palette.primary.contrastText : theme.palette.text.primary,
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: `0 10px 20px var(--shadow-color-${theme.palette.mode})`,
                  cursor: 'pointer',
                },
              }}
              onClick={() => handleSkillClick(skill)}
            >
              <CardContent>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    color: selectedSkill === skill ? theme.palette.primary.contrastText : theme.palette.primary.main 
                  }}
                >
                  {React.cloneElement(skill.icon, { fontSize: 'large', sx: { mr: 1 } })}
                  <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    {skill.title}
                  </Typography>
                </Box>
                <Typography variant="body2" color={selectedSkill === skill ? theme.palette.primary.contrastText : "text.secondary"}>
                  {skill.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default Skills;