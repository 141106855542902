import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Hidden, Box, useTheme, Switch } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ColorModeContext } from '../App';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (!element) {
      console.error(`Element with id "${id}" not found`);
      return;
    }
    const offset = -70;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const navItems = ['About', 'Skills', 'Projects', 'CV & Certifications', 'Contact'];

  const NavButton = ({ text }) => (
    <Button
      onClick={() => handleScroll(text.toLowerCase().replace(/ & /g, ''))}
      sx={{
        mx: 1,
        px: 2,
        py: 1,
        borderRadius: '8px',
        transition: 'all 0.3s ease',
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        },
      }}
    >
      {text}
    </Button>
  );

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        backgroundColor: theme.palette.background.paper,
        height: '100%',
      }}
    >
      <List>
        {navItems.map((text) => (
          <ListItem
            button
            key={text}
            onClick={() => {
              handleScroll(text.toLowerCase().replace(/ & /g, ''));
              toggleDrawer(false)();
            }}
            sx={{
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.mode === 'light' 
            ? theme.palette.background.paper 
            : theme.palette.background.default,
          color: theme.palette.text.primary,
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              fontWeight: 'bold',
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Dan Polyukhovich
          </Typography>
          <Hidden smDown>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {navItems.map((text) => (
                <NavButton key={text} text={text} />
              ))}
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                <Brightness7Icon />
                <Switch
                  checked={theme.palette.mode === 'dark'}
                  onChange={colorMode.toggleColorMode}
                  color="default"
                  inputProps={{ 'aria-label': 'toggle dark mode' }}
                />
                <Brightness4Icon />
              </Box>
            </Box>
          </Hidden>
          <Hidden smUp>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Header;