import React from 'react';
import { Typography, Paper, Box, Avatar, Grid, Chip, useTheme } from '@mui/material';
import { GitHub, LinkedIn, Twitter } from '@mui/icons-material';
import avatarImage from '../assets/avatar.jpeg';

const About = () => {
  const theme = useTheme();

  const socialLinks = [
    { icon: <GitHub />, url: 'https://github.com/Khovic' },
    { icon: <LinkedIn />, url: 'https://www.linkedin.com/in/khovic/' },
    // { icon: <Twitter />, url: 'https://twitter.com/yourusername' },
  ];

  const keySkills = ['CI/CD', 'Cloud', 'Container Orchestration', 'Automation', 'Infrastructure as Code', 'Monitoring'];

  return (
    <Paper 
      elevation={3} 
      id="about" 
      className="glass fade-in" 
      sx={{ 
        p: 4, 
        my: 4, 
        backgroundColor: theme.palette.background.paper,
        borderRadius: '16px',
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar 
              alt="Dan Polyukhovich" 
              src={avatarImage} 
              sx={{ 
                width: 200, 
                height: 200, 
                border: `4px solid ${theme.palette.primary.main}`,
                boxShadow: `0 0 20px ${theme.palette.primary.main}30`,
              }} 
            />
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              {socialLinks.map((link, index) => (
                <Box 
                  key={index} 
                  component="a" 
                  href={link.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  sx={{ 
                    color: theme.palette.text.secondary,
                    mx: 1,
                    transition: 'color 0.3s',
                    '&:hover': { color: theme.palette.primary.main },
                  }}
                >
                  {link.icon}
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
            About Me
          </Typography>
          <Typography variant="body1" paragraph sx={{ mb: 3 }}>
            Hey, My name is Dan and I am a DevOps Engineer from Israel. I always have been fascinated by computers and technology, As a child I would often tinker with tech and electronics. When not busy with tech you'll find me at the gym, watching movies, listening to literally all kinds of music, or just taking a walk.
          </Typography>
          <Typography variant="body1" paragraph>
            My expertise includes:
          </Typography>
          <Box sx={{ mb: 3 }}>
            {keySkills.map((skill, index) => (
              <Chip 
                key={index} 
                label={skill} 
                sx={{ 
                  mr: 1, 
                  mb: 1, 
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }} 
              />
            ))}
          </Box>
          <Typography variant="body1">
            I'm always eager to take on new challenges and contribute to innovative and interesting projects.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default About;